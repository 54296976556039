<template>

<com-content :inner="true">

	<div class="preview">

		<div class="preview-head">

			<div class="preview-head-left">
				<div /><div /><div />
			</div>

			<div class="preview-head-url"><i class="fa-solid fa-lock"></i>cardboardevents.com</div>

			<div class="preview-head-right"></div>

		</div>

		<div class="preview-body" v-on:click="onPlayClick">
		
			<i class="preview-body-icon fa-solid fa-play" v-if="!playing"></i>

			<video ref="video" preload="auto" :controls="(playing) ? 'controls' : false" poster="/img/home.jpg">
				<source :src="$api.api + '../../files/home.mp4'" type="video/mp4">
			</video>

		</div>

	</div>

</com-content>
	
</template>

<script>

export default {
	
	components: {

		'com-content': () => import('./../common/Content'),

	},

	data: function() {

		return {
			playing: false
		}

	},

	methods: {

		onPlayClick: function() {

			this.playing = true

			this.$refs.video.play()

		}

	}

}

</script>

<style scoped>

.preview {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.preview-head {
	background-color: #1F2937;
	height: 40px;
	border-radius: 10px 10px 0px 0px;
	padding: 0px 10px;
	display: flex;
	align-items: center;
}

.preview-head-url {
	background-color: #374151;
	border-radius: 5px;
	width: 500px;
	height: 28px;
	text-align: center;
	line-height: 28px;
	color: #fff;
	font-size: 14px;
}

.preview-head-url .fa-lock {
	margin-right: 10px;
	opacity: 0.5;
	font-size: 12px;
}

.preview-head-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 40px;
	width: 250px;
}

.preview-head-left {
	display: flex;
	align-items: center;
	height: 40px;
	width: 250px;
}

.preview-head-left > div {
	width: 16px;
	height: 16px;
	border-radius: 8px;
	margin-right: 4px;
}

.preview-head-left > div:nth-child(1) {
	background-color: #EE6A5F;
}

.preview-head-left > div:nth-child(2) {
	background-color: #F5BD4F;
}

.preview-head-left > div:nth-child(3) {
	background-color: #61C454;
}

.preview-body {
	background-color: #fff;
	border: 1px solid #171f2b;
	border-top-width: 0px;
	flex-grow: 1;
	overflow: hidden;
	cursor: pointer;
	border-radius: 0px 0px 10px 10px;
	background-image: url(/preview.png);
	background-size: 1000px auto;
	background-repeat: no-repeat;
}

.preview-body video {
	width: 100%;
	margin: 0px;
	display: block;
	height: auto;
}

.preview-body-icon {
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 128px;
	color: #ffbe7a;
	transition: transform 100ms linear;
}

.preview-body:hover .preview-body-icon {
	transform: translate(-50%, -50%) scale(1.1);
}

</style>